<template>
  <div class="AppointmentsView-container">
    <div v-if="typeId < 100">
      <label id="titulo">
        Agenda de {{especialidad.name}}
      </label><br>
    </div>
    <div v-else-if="typeId <= 199">
      <label id="titulo" >
        Agenda de consulta general
      </label><br>
    </div>
    <div v-else-if="typeId == 200">
      <label id="titulo" >
        Agenda de Trabajo Social
      </label><br>
    </div>
    <div v-else>
      <label id="titulo" >
        Agenda de {{typeId}}
      </label>
      <label>Gabinete</label>
    </div>
    <label id="fecha"> {{ $dateString(dateDateStr) }}</label>
    <label id="fecha"> {{dateDateStr}}</label>
    <div class="busqueda">
      <el-divider v-if="userId"/>
      <div class="selects-agendas" v-if="!userId" >
        <div>
          <label>Tipo de cita</label>
          <el-select
            v-model="otherType"
            placeholder="Seleccionar"
            style="width: 195px;"
            @change="handlerAppointmentsCount()"
          >
            <el-option
              v-for="(tipo, idx) in tipoAgenda"
              :key="idx"
              :label="tipo.tipo"
              :value="tipo.tipo"
              style="padding: 8px !important"
            >
              <el-badge :value="tipo.total">
                <label>{{ tipo.tipo }}</label>
              </el-badge>
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button
            style="background: #8F70C7; color: #fff; border-radius: 8px;"
            @click="handlerAppointmentsCount('todas las citas');"
          >
            Todas las citas
          </el-button>
        </div>
        <div>
          <div>
            <label for="nombre">Nombre</label>
            <input
              type="search"
              v-model="nameSearch"
              style="
                border-radius: 8px;
                border: solid 1px #0003;
                padding: 8px;
                outline: none;"
              >
          </div>
        </div>
      </div>
    </div>
    <div
      class="appointments-table"
      v-for="(ap, idx) in citas"
      :key="idx"
    >
      <div :class="{'appointment-checked': ap.checked}">
        <div style="margin-bottom: 10px;">
          <label style="font-size: 22px; padding: 10px;">{{ ap.time }}</label>
          <label v-if="ap.checked === 1">Paciente evaluado</label>
        </div>
        <div>
          <div class="appointment-row">
            <div style="width: 400px" @click="sendrowsValue(ap)">
              <label>{{ ap.nombrePaciente }} {{ ap.apellidoPaciente}}</label><br>
              <label>{{ ap.type}}</label>
              <label v-if="ap.procedure"> ({{ ap.procedure}})</label>
              <label v-if="ap.gabinete"> - {{ ap.gabinete }}</label>
            </div>
            <div v-if="typeId !== '200'">
              <div v-if="ap.status">
                <label
                  style="font-weight: bold;font-size: 18px;">{{areas[ap.status - 1].name}}</label>
                <div
                  :style="{
                    background: areas[ap.status - 1].color,
                    height: '10px',
                    'border-radius': '8px',
                    width: '140px',
                  }"
                />
              </div>
            </div>
            <div class="options-evaluations">
              <el-button
                id="btn-evaluation"
                v-if="typeId <= 100 && roleType === 'MD'"
                @click="goToEvaluation(idx, 'ofta')"
              >
                Realizar evaluación Oftalmológica
              </el-button>
              <el-button
                id="btn-evaluation"
                v-if="
                  typeId <= 100  && (roleType === 'OPTO' || roleType === 'MD')
                  || typeId.length > 5 && (roleType === 'OPTO' || roleType === 'MD')
                "
                @click="goToEvaluation(idx, 'opto')"
              >
                Realizar evaluación Optométrica
              </el-button>
              <el-button
                id="btn-evaluation"
                v-if="typeId >= 200 && roleType === 'TS'"
                @click="goToEvaluation(idx, 'socialeva')"
              >
                Realizar evaluación Socioeconómica
              </el-button>
            </div>
            <div class="comentarios-apView" v-if="ap.comments">
              <label class="comments-ap">Comentario</label><br>
              <label class="comments-ap">{{ ap.comments }}</label>
            </div>
          </div>
          <el-divider />
        </div>
      </div>
    </div>
    <el-dialog
      id="main-popup"
      width="90%"
      :visible.sync="dialogPatientVisible"
    >
      <dialogPatientList :rowsValue="currentRow"/>
    </el-dialog>
  </div>
</template>

<script>
import dialogPatientList from '../../../components/dialogPatientListNew';

const moment = require('moment');

export default {
  data() {
    return {
      nameSearch: null,
      citasBase: [],
      roleType: null,
      typeId: null,
      clase: null,
      userId: null,
      especialidad: [],
      citas: [],
      currentRow: [],
      dateDateStr: null,
      otherType: null,
      procedure: null,
      specialty: null,
      tipoAgenda: [],
      dialogPatientVisible: false,
      areas: [
        { name: 'Recepción', id: 1, color: '#0097e6' },
        { name: 'Optometría', id: 2, color: '#8c7ae6' },
        { name: 'Médico', id: 3, color: '#ff7675' },
        { name: 'Médico Pasante', id: 4, color: '#4cd137' },
        { name: 'Trabajo Social', id: 5, color: '#227093' },
      ],
    };
  },
  components: {
    dialogPatientList,
  },
  methods: {
    saveAppointmentInLocalStorage(appointmentInfo) {
      localStorage.setItem('currentRow', JSON.stringify(appointmentInfo));
      const tipoDeCita = appointmentInfo.type;
      const subjectDeCita = appointmentInfo.subject;
      if (tipoDeCita === 'CITA - TRABAJO SOCIAL') {
        const { userId, id } = appointmentInfo;
        const social = { userId, patientId: id };
        localStorage.setItem('appointmentsTypeSelected', JSON.stringify(social));
      } else if (subjectDeCita === 'Médico') {
        const { id } = appointmentInfo;
        const medical = { tipo: '100', patientId: id };
        localStorage.setItem('appointmentsTypeSelected', JSON.stringify(medical));
      } else {
        const { especialidadId, id } = appointmentInfo;
        const specialty = { tipo: especialidadId, patientId: id };
        localStorage.setItem('appointmentsTypeSelected', JSON.stringify(specialty));
      }
    },
    showCurrentRow() {
      const rowLocalStor = JSON.parse(localStorage.getItem('currentRow'));
      if (rowLocalStor) {
        this.sendrowsValue(rowLocalStor);
      } else {
        this.dialogPatientVisible = false;
      }
    },
    async getRole() {
      const role = await JSON.parse(localStorage.getItem('vuex'));
      this.roleType = role.auth.user.roleId;
      this.typeId = this.$route.params.id;
      if (this.typeId < 100) {
        await this.getSpecialty();
        await this.getSpecialities();
        await this.getFilterCitas();
      } else if (this.typeId <= 100) {
        await this.getAppointmentsMedical();
        await this.getAllMedicalAppointments();
      } else if (this.typeId <= 200) {
        this.userId = this.$route.params.userId;
        await this.getSocialApponments();
      } else {
        await this.getGabineteApponments();
        // await this.getSpecialty();
        // await this.getSpecialities();
        // await this.getFilterCitas();
      }
    },
    goToEvaluation(idx, typeEvaluation) {
      const patientId = this.citas[idx].id;
      if (this.typeId <= 100 || this.typeId.length > 5) {
        const { idcita } = this.citas[idx];
        if (typeEvaluation === 'ofta') {
          this.saveAppointmentInLocalStorage(this.citas[idx]);
          this.$router.push(`/admin/evaluations/${typeEvaluation}/${patientId}/${idcita}`);
        } else if (typeEvaluation === 'opto') {
          this.saveAppointmentInLocalStorage(this.citas[idx]);
          if (this.citas[idx].optometricEvaluation) {
            this.$router.push(`/admin/evaluations/opto/${patientId}/${idcita}/${this.citas[idx].optometricEvaluation}`);
          } else {
            this.$router.push(`/admin/evaluations/opto/${patientId}/${idcita}`);
          }
        } else {
          this.saveAppointmentInLocalStorage(this.citas[idx]);
          this.$router.push(`/admin/evaluations/${typeEvaluation}/${patientId}/${idcita}`);
        }
      } else if (this.typeId <= 200) {
        this.saveAppointmentInLocalStorage(this.citas[idx]);
        this.$router.push(`/admin/evaluations/${typeEvaluation}/${patientId}/${this.citas[idx].idcita}/`);
        // this.$router.push(`/admin/evaluations/${typeEvaluation}/${patientId}`);
      }
    },
    // Especialidades funciones
    async getSpecialities() {
      try {
        const date = new Date();
        const dateFotmat = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const request = await this.axios(`patient/specialty/specialtyCount/${this.typeId}/${dateFotmat}`);
        const { data } = request;
        if (request) {
          this.tipoAgenda.push(...data);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getSpecialty() {
      try {
        const request = await this.axios(`patient/specialty/${this.typeId}`);
        if (request) {
          this.especialidad = request.data;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getFilterCitas(todas) {
      const date = moment(this.dateDateStr, 'YYYY-MM-DD').format('YYYY-MM-DD');
      let tipos = this.otherType;
      const subject = 'Especialidad';
      const specialty = this.especialidad.id;
      if (todas === 'todas las citas') tipos = null;
      const request = await
      this.axios(`/patient/subsecuente/citas/especialidad/v2?type=${tipos}&subject=${subject}&specialty=${specialty}&date=${date}`);
      if (request.data) {
        const formatData = request.data.map((item) => ({
          id: item.patientInfo.id,
          time: item.time,
          nombrePaciente: item.patientInfo.info[0].firstName,
          apellidoPaciente: item.patientInfo.info[0].lastName,
          type: item.type,
          status: item.status?.statusId,
          comments: item.comments,
          idcita: item.id,
          checked: item.checked,
          canceled: item.canceled,
          optometricEvaluation: item?.optometricEvaluation?.id,
        }));

        this.citas = formatData;
        this.citasBase = formatData;
      }
    },
    // Consulta general funciones
    async getAllMedicalAppointments(todas) {
      const date = moment(this.dateDateStr, 'YYYY-MM-DD').format('YYYY-MM-DD');
      let tipos = this.otherType;
      const subject = null;
      const specialty = null;
      if (todas === 'todas las citas') tipos = null;
      const request = await this.axios(`/patient/subsecuente/citas/medico?type=${tipos}&subject=${subject}&specialty=${specialty}&date=${date}`);
      if (request.data) {
        const formatData = request.data.map((item) => ({
          id: item?.patientInfo?.id,
          time: item?.time,
          nombrePaciente: item?.patientInfo?.info[0].firstName,
          apellidoPaciente: item?.patientInfo?.info[0].lastName,
          type: item?.type,
          status: item?.status?.statusId,
          comments: item?.comments,
          idcita: item?.id,
          checked: item?.checked,
          canceled: item?.canceled,
          optometricEvaluation: item?.optometricEvaluation?.id,
        }));

        this.citas = formatData;
        this.citasBase = formatData;
      }
    },
    async getAppointmentsMedical() {
      try {
        const date = new Date();
        const dateFotmat = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const request = await this.axios(`patient/specialty/appointmentConsultagralCount/${dateFotmat}`);
        const { data } = request;
        if (request) {
          this.tipoAgenda.push(...data);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // Trabajo social funciones
    async getSocialApponments() {
      try {
        const date = moment(this.dateDateStr, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const request = await this.axios(`/patient/subsecuente/social?&date=${date}`);
        const { data } = request;
        if (data) {
          this.citas = data;
          this.especialidad = { name: `${data[0].trabajadorSocial} ${data[0].apellidoTS}` };
        }
      } catch (error) {
        // console.error(error);
      }
    },
    // Gabinete funciones
    async getGabineteApponments() {
      try {
        const date = moment(this.dateDateStr, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const request = await this.axios(`/patient/subsecuente/citas/gabinete?&date=${date}&subject=Gabinete&procedure=${this.typeId}`);
        const { data } = request;
        if (data) {
          this.citas = data;
          this.citasBase = data;
          this.especialidad = { name: 'Gabinete' };
        }
      } catch (error) {
        // console.error(error);
      }
    },
    async sendrowsValue(item) {
      const { id } = item;
      const patient = await this.getPatientData(id);
      this.currentRow = {
        ...patient,
        id,
      };
      this.dialogPatientVisible = true;
    },
    selectAgeRange(birthdate) {
      const bd = moment(birthdate);
      const today = moment().now;
      const diff = Math.abs(bd.diff(today, 'years'));
      if (diff < 1) {
        return 'Menor de 1 Año';
      }
      if (diff < 6) {
        return '1 a 5 Años';
      }
      if (diff < 12) {
        return '6 a 11 Años';
      }
      if (diff < 18) {
        return '12 a 17 Años';
      }
      if (diff < 30) {
        return '18 a 29 Años';
      }
      if (diff < 60) {
        return '30 a 60 Años';
      }
      return '60 Años o mas';
    },
    async getPatientData(patientId) {
      try {
        let patient = {};
        const response = await this.axios.get(`patient/info/${patientId}`);
        const { data } = response;
        patient = data ? {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          ageRange: this.selectAgeRange(data.birthdate),
        } : this.patient;
        const response2 = await this.axios.get(`patient/profile/${patientId}`);
        const data2 = response2.data;
        patient = data2 ? {
          ...data,
          ...data2,
          fullName: `${data.firstName} ${data.lastName}`,
          fullAddress: `${data2.address}, \
            ${data2.city}, ${data2.state}, ${data2.country}`,
          ageRange: this.selectAgeRange(data.birthdate),
        } : patient;
        return patient;
      } catch (error) {
        const response = await this.axios.get(`patient/info/${patientId}`);
        const { data } = response;
        return {
          ...data,
          fullName: `${data.firstName} ${data.lastName}`,
          ageRange: this.selectAgeRange(data.birthdate),
        };
      }
    },
    // handlers
    async handlerAppointmentsCount(todas) {
      if (this.typeId < 100) {
        await this.getFilterCitas(todas);
      } else if (this.typeId <= 100) {
        await this.getAllMedicalAppointments(todas);
      }
    },
  },
  async created() {
    const date = new Date();
    this.dateDateStr = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    await this.getRole();
    this.showCurrentRow();
  },
  watch: {
    nameSearch() {
      this.citas = this.citasBase;
      const nameFilter = this.nameSearch.toUpperCase().trim();
      const filter = this.citas.filter((cita) => cita.nombrePaciente.includes(nameFilter));
      this.citas = filter;
    },
  },
};
</script>

<style scoped lang="scss">
.comentarios-apView {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 2.5px solid #8a8a8a;
  .comments-ap {
    color: rgb(105, 105, 105);
  }
}
.AppointmentsView-container {
  padding: 15px;
  #titulo, #fecha {
    color:#8F70C7;
    font-weight: bold;
  }
  #titulo {
    font-size: 32px;
  }
  .appointment-checked {
    background-color: #540cb130;
    padding-top: 10px;
    border-radius: 12px;
  }
  .appointments-table {
    gap:15px;
    margin-top: 10px;

    .appointment-row {
      display: flex;
      gap: 10px;
      padding-left: 10px;
    }
  }
  .busqueda {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
  }
  .selects-agendas {
    margin-top: 30px;
    display:flex;
    text-align: left;
    align-items: flex-end;
    gap: 10px;
    div:nth-child(1) {
      display: flex;
      flex-direction: column;
    }
  }
  label {
    color: #6c33c1;
    font-weight: bold;
  }
  .dialog-header {
    background-color: #8F70C7;
    margin-bottom: 5px;
    height: 20px;
    border-radius: 12px;
  }
  .dialog-fotter {
    display: flex;
    justify-content: right;
  }
  .options-evaluations {
    #btn-evaluation {
      background-color: #8F70C7;
      color: #fff;
      border-radius: 12px;
      font-weight: 600;
      border: none;
    }
  }
}
</style>
